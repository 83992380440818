import React from "react";
import { CoreComponent } from "../core/CoreComponent";
import { UserSession } from "../core/UserSession";
import { Apiserver } from "../core/Apiserver";
import { log, Validator, getDateFromDict, getDateFromDictWithNA } from "../core/Util";
import { DBConstants } from "../core/Constants";
import { Link } from "react-router-dom";


export class InquiryView extends CoreComponent {

    constructor(props) {
        super();
        this.state.consent = {}
    }

    componentDidMount() {
        if (UserSession.isLoggedIn()) {
            this.getdata();
        }
    }
    async getdata() {
        let datain = {}

        datain[DBConstants.INQUIRY_ID] = this.props.match.params.inquiryid

        if (UserSession.isUser())
            datain[DBConstants.ACCOUNT_TYPE] = DBConstants.ACCOUNT_TYPE_USER
        else
            datain[DBConstants.ACCOUNT_TYPE] = DBConstants.ACCOUNT_TYPE_CORPORATE

        this.showAnimation();
        let response = await Apiserver.postcall("get_inquiry", datain);
        this.closeAnimation();


        let error = {};
        if (!Validator.checkResponseData(response, error)) {
            this.setState({ error: error });
            window.scrollTo({ top: 0, behavior: "smooth" });
        } else {
            this.setState({ inquiry: response.data })
        }

    }


    handleButtonClick = () => {
        this.props.history.goBack();
    }

    requestUser = () => {
        this.postdata(0);
    }

    faultClose = () => {
        this.postdata(1);
    }

    unresolvedclose = () => {
        this.postdata(2);
    }

    async postdata(type) {

        let url = ""
        let message = ""
        if (type == 0) {
            url = "request_user_to_close"
            message = "Email has been sent to user to close this inquiry"
        }
        else if (type == 1) {
            url = "close_at_fault_inquiry"
            message = "This inquiry has been closed as valid and customer at fault, this can impact your rating"
        }
        else if (type == 2) {
            url = "close_unresolved_inquiry"
            message = "This inquiry has been closed as unresolved"
        }

        let data = {}
        data[DBConstants.INQUIRY_ID] = this.state.inquiry.inquiryid

        this.showAnimation();
        let response = await Apiserver.postcall(url, data);
        this.closeAnimation();

        let error = {};
        if (!Validator.checkResponseData(response, error)) {
            this.setState({ error: error })
            window.scrollTo({ top: 0, behavior: "smooth" });
        }
        else {
            this.setState({ inquiry: response.data })
            this.redirectWithMessage(this.props, "/main/inquires/inquiryview/" + this.state.inquiry.inquiryid, message)
        }
    }

    render() {
        return (
            <div className="col p0 m0 mw700">

                {this.getMessagePanel()}
                <div className="container mw99p mt10 ml0 mr10 mb0 pt0 pl0 pr0 pb10 bglightgray">
                    <div className="row m0 pt5 pl0 pr0 pb0">
                        <div className="col-12"><span className="propertyname fcmaroon">Inquiry Details</span></div>
                    </div>
                    {this.state.inquiry && <div>
                        <div className="row m0 pt5 pl0 pr0 pb0">
                            <div className="col-12"><span className="propertyname">Inquiry Id : </span><span className="propertyvalue">{this.state.inquiry.inquiryid && this.state.inquiry.inquiryid}</span></div>
                            <div className="col-12"><span className="propertyname">Domain :</span><span className="propertyvalue">{this.state.inquiry.domain && this.state.inquiry.domain}</span></div>
                            <div className="col-12"><span className="propertyname">Domain Id : </span><span className="propertyvalue">{this.state.inquiry.programid && this.state.inquiry.programid}</span></div>
                            <div className="col-12"><span className="propertyname">Date Created : </span><span className="propertyvalue">{this.state.inquiry.date && getDateFromDict(this.state.inquiry.date)}</span></div>
                            <div className="col-12"><span className="propertyname">Status : </span><span className="propertyvalue">{this.state.inquiry.status && this.state.inquiry.status}</span></div>
                            <div className="col-12"><span className="propertyname">User Email : </span><span className="propertyvalue">{this.state.inquiry.emailid && this.state.inquiry.emailid}</span></div>
                            <div className="col-12"><span className="propertyname">Ack : </span><span className="propertyvalue">{this.state.inquiry.ack && this.state.inquiry.ack.toString()}</span></div>
                            <div className="col-12"><span className="propertyname">Date Ack : </span><span className="propertyvalue">{this.state.inquiry.ackdate && getDateFromDictWithNA(this.state.inquiry.ackdate)}</span></div>
                            <div className="col-12"><span className="propertyname">Due By : </span><span className="propertyvalue fcmaroon">{this.state.inquiry.duedate && getDateFromDict(this.state.inquiry.duedate)}</span></div>
                            <div className="col-12"><span className="propertyname">Close Date : </span><span className="propertyvalue">{this.state.inquiry.ackdate && getDateFromDictWithNA(this.state.inquiry.closedate)}</span></div>
                        </div>

                        <div className="row m0 pt5 pl0 pr0 pb0">
                            <div className="col-12"><span className="propertyname">Type: </span><span className="propertyvalue">{this.state.inquiry.type}</span></div>
                            <div className="col-12"><span className="propertyname">Resident Of: </span><span className="propertyvalue">{this.state.inquiry.residentof}</span></div>
                            <div className="col-12"><span className="propertyname">More Info: </span><span className="propertyvalue">{this.state.inquiry.moreinfo}</span></div>
                        </div>
                        {(this.state.inquiry.status && this.state.inquiry.status == "pending") &&
                            <div className="d-flex flex-row hc vc mt20 ml10 mr10">
                                <button onClick={this.requestUser} type="button" className=" btn btn-primary mr5">
                                    Request User to Close
                                </button>
                                <button onClick={this.faultClose} type="button" className=" btn btn-primary mr5">
                                    Customer at fault Close
                                </button>
                                {UserSession.isAdmin() &&
                                    <button onClick={this.unresolvedclose} type="button" className=" btn btn-primary">
                                        Unresolved Close
                                    </button>}
                            </div>}

                    </div>}

                </div>

                <div className="row hc vc mt10 ml10 mr10">
                    <button
                        onClick={this.handleButtonClick}
                        type="button"
                        className=" btn btn-primary">
                        Back
                    </button>
                </div>

            </div >

        );
    }
}

