import React from "react";
import { CoreComponent } from "../core/CoreComponent";
import { UserSession } from "../core/UserSession";
import { Apiserver } from "../core/Apiserver";
import { log, Validator, getDateFromDictWithNA } from "../core/Util";
import { DBConstants } from "../core/Constants";
import { Link } from "react-router-dom";


export class ConsentView extends CoreComponent {

    constructor(props) {
        super();
        this.state.consent = {}
    }

    componentDidMount() {
        if (UserSession.isLoggedIn()) {
            this.getdata();
        }
    }
    async getdata() {
        let datain = {}

        if (this.props.match.params.enc_consentid)
            datain[DBConstants.ENC_CONSENT_ID] = this.props.match.params.enc_consentid
        else
            datain[DBConstants.CONSENT_ID] = this.props.match.params.consentid

        if (UserSession.isUser())
            datain[DBConstants.ACCOUNT_TYPE] = DBConstants.ACCOUNT_TYPE_USER
        else
            datain[DBConstants.ACCOUNT_TYPE] = DBConstants.ACCOUNT_TYPE_CORPORATE

        this.showAnimation();
        let response = await Apiserver.postcall("get_consent", datain);
        this.closeAnimation();


        let error = {};
        if (!Validator.checkResponseData(response, error)) {
            this.setState({ error: error });
            window.scrollTo({ top: 0, behavior: "smooth" });
        } else {
            response.data.certificatelink = "../../../clientcert/" + response.data.corporateid + "/" + response.data.programid;
            if (response.data.dsrtype == "1") {
                let mailto = "mailto:" + response.data.dsrinfo + "?subject=Consent Output Out&body=Kindly opt me out my details: ";
                mailto = mailto + " Email(" + response.data.useremailid + "), ConsentId (" + response.data.consentid + "), programId(" + response.data.programid + ")";
                response.data.mailto = mailto;
            }
            this.setState({ consent: response.data })
        }

    }

    handleButtonClick = () => {
        this.props.history.goBack();
    }

    onClickViewprogramLink = (url) => {
        if (!url.startsWith("http://") || !url.startsWith("https://"))
            url = "http://" + url
        window.open(url);
    };

    render() {
        return (
            <div className="col p0 m0 mw700">

                {this.getMessagePanel()}
                <div className="container mw99p mt10 ml0 mr10 mb0 pt0 pl0 pr0 pb10 bglightgray">
                    <div className="row m0 pt5 pl0 pr0 pb0">
                        <div className="col-12"><span className="propertyname fcmaroon">Consent Details</span></div>
                    </div>
                    {this.state.consent && <div>
                        <div className="row m0 pt5 pl0 pr0 pb0">
                            <div className="col-12"><span className="propertyname">{this.state.consent.corporatename}</span></div>
                            <div className="col-12"><span className="propertyvalue">Corporate Id : {this.state.consent.corporateid}</span></div>
                            <div className="col-12"><span className="propertyvalue">Consent Id : {this.state.consent.consentid}</span></div>
                        </div>

                        <div className="row m0 pt5 pl0 pr0 pb0">
                            <div className="col-12"><span className="propertyname">{this.state.consent.programname}</span></div>
                            <div className="col-12"><span className="propertyvalue">program Id: {this.state.consent.programid}</span></div>
                            <div className="col-12"><span className="propertyvalue"><a href={this.state.consent.certificatelink} target="_blank"><u>Cetificate link</u></a></span></div>
                            <div className="col-12"><span className="propertyvalue"><a href={this.state.consent.link} target="_blank"><u>Privacy Page</u></a></span></div>

                        </div>

                        <div className="row m0 pt5 pl0 pr0 pb0">
                            <div className="col-12"><span className="propertyname">Data Collected</span></div>
                            <div className="col-12"><span className=" propertyvalue prewrap">{this.state.consent.datacollected}</span></div>
                        </div>
                        <div className="row m0 pt5 pl0 pr0 pb0">
                            <div className="col-12"><span className="propertyname">Usage</span></div>
                            <div className="col-12"><span className=" propertyvalue">{this.state.consent.usage}</span></div>
                        </div>
                        <div className="row m0 pt5 pl0 pr0 pb0">
                            <div className="col-12"><span className="propertyname">Storage</span></div>
                            <div className="col-12"><span className=" propertyvalue">{this.state.consent.storage}</span></div>
                        </div>
                        <div className="row m0 pt5 pl0 pr0 pb0">
                            <div className="col-12"><span className="propertyname">Other information</span></div>
                            <div className="col-12"><span className=" propertyvalue">{this.state.consent.otherinfo && this.state.consent.otherinfo}</span></div>
                        </div>
                        {this.state.consent.dsrtype == "1" ?
                            <div className="row m0 pt5 pl0 pr0 pb0">
                                <div className="col-12"><span className="propertyname">Opt-out email</span></div>
                                <div className="col-12"><span className=" propertyvalue">{this.state.consent.dsrinfo}</span></div>
                                <div className="col-12">
                                    <span className=" propertyvalue prewrap">
                                        <a href={this.state.consent.mailto} target="_blank"><u>click to email</u></a>
                                    </span>
                                </div>
                            </div> :

                            <div className="row m0 pt5 pl0 pr0 pb0">
                                <div className="col-12"><span className="propertyname">Opt-out link</span></div>
                                <div className="col-12">
                                    <span className=" propertyvalue prewrap">
                                        <a href={this.state.consent.dsrinfo} target="_blank"><u>{this.state.consent.dsrinfo}</u></a>
                                    </span>
                                </div>
                            </div>}

                    </div>}

                </div>

                <div className="row hc vc mt10 ml10 mr10">
                    <button
                        onClick={this.handleButtonClick}
                        type="button"
                        className=" btn btn-primary">
                        Back
                        </button>
                </div>

            </div >

        );
    }
}

