import React from "react";
import "../css/CustomerCert.css";
import { CoreComponent } from "../core/CoreComponent";
import { log, addData, Validator, getValue } from "../core/Util";
import { DBConstants, ErrorMsg } from "../core/Constants";
import { Apiserver } from "../core/Apiserver";

export class CustomerCert extends CoreComponent {

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    this.getdata();
  }
  async getdata(datain) {
    var datain = {}
    datain[DBConstants.Corporate_ID] = this.props.match.params.corporateid;
    datain[DBConstants.PROGARM_ID] = this.props.match.params.programid;
    this.showAnimation();
    let response = await Apiserver.postcall("get_PROGARM_customer_certificate", datain);
    this.closeAnimation();
    let error = {};
    if (!Validator.checkResponseData(response, error)) {
      this.setState({ error: error });
      window.scrollTo({ top: 0, behavior: "smooth" });
    } else {
      this.setState({ program: response.data })
    }
  }

  render() {
    return (
      <div>
        <div className="base">
          <div style={{ "borderBottomStyle": "solid", "borderColor": "#77c146", "padding": "2px", "paddingBottom": "7px", "marginBottom": "10px" }}>
            <div><span>
              This site is hosted at www.trustedroads.com
          </span></div>
            <span>
              <span className="corporatename">{this.state.program && this.state.program.corporatename}</span> information collection and handling verification page
          </span>
          </div>
          <img src="https://content.trustedroads.com/plugin/logo.png" style={{ "width": "75%", "margin": "0px", "padding": "0px" }} />
          <div id="trustedroadsinfo" style={{ "padding": "10px 5px 5px 5px", "height": "auto", "color": "#000", "fontWeight": "400", "textAlign": "left" }}>
            <div style={{ "marginTop": "10px" }}>	<span>
              <span className="corporatename">{this.state.program && this.state.program.corporatename}</span> collects and handles your Personally Identifiable Information (PII) based on the following details	</span></div>
            <div className="programs">
              {this.state.program && <div>
                {this.state.program.datacollected.length > 0 && <div>
                  <span className="tilte">Information Collected</span>
                  <div className="data">{this.state.program.datacollected}</div>
                </div>}

                {this.state.program.usage.length > 0 && <div>
                  <span className="tilte">Usage</span>
                  <div className="data">{this.state.program.usage}</div>
                </div>}

                {this.state.program.storage.length > 0 && <div>
                  <span className="tilte">Storage</span>
                  <div className="data">{this.state.program.storage}</div>
                </div>}
              </div>
              }
            </div>
          </div>
        </div>
      </div>
    );
  }
}
