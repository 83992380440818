import React from "react";
import { CoreComponent } from "../core/CoreComponent";
import { UserSession } from "../core/UserSession";
import { Apiserver } from "../core/Apiserver";
import { log, Validator, getDateFromDict } from "../core/Util";
import { DBConstants } from "../core/Constants";
import { Link } from "react-router-dom";
import { Startup } from "../core/Util"
import { PROD } from "../core/Util"


export class ProgramView extends CoreComponent {

    constructor(props) {
        super();
        this.state.program = {}
    }

    componentDidMount() {
        if (UserSession.isLoggedIn()) {
            this.getdata();
        }
    }
    async getdata(datain) {

        var datain = {}

        datain[DBConstants.PROGRAM_ID] = this.props.match.params.programid
        if (UserSession.isUser())
            datain[DBConstants.ACCOUNT_TYPE] = DBConstants.ACCOUNT_TYPE_USER
        else
            datain[DBConstants.ACCOUNT_TYPE] = DBConstants.ACCOUNT_TYPE_CORPORATE
        this.showAnimation();
        let response = await Apiserver.postcall("get_program", datain);
        this.closeAnimation();


        let error = {};
        if (!Validator.checkResponseData(response, error)) {
            this.setState({ error: error });
            window.scrollTo({ top: 0, behavior: "smooth" });
        } else {

            let envForJs = Startup.ENV + ".js";
            if (Startup.ENV == PROD)
                envForJs = "js";
            let links = {
                "main": "https://content.trustedroads.com/" + Startup.ENV + "/" + response.data.corporateid + "/trustedroads." + response.data.programid + "." + envForJs,
                "submit": "https://content.trustedroads.com/" + Startup.ENV + "/" + response.data.corporateid + "/trustedroads_submitdata." + response.data.programid + "." + envForJs
            }

            this.setState({ program: response.data, links: links })
        }

    }



    async generatescript(programid, env) {

        var datain = {}
        datain[DBConstants.PROGARM_ID] = programid
        datain[DBConstants.ENV] = env
        if (UserSession.isUser())
            datain[DBConstants.ACCOUNT_TYPE] = DBConstants.ACCOUNT_TYPE_USER
        else
            datain[DBConstants.ACCOUNT_TYPE] = DBConstants.ACCOUNT_TYPE_CORPORATE

        this.showAnimation();
        let response = await Apiserver.postcall("gen_script", datain);
        this.closeAnimation();


        let error = {};
        if (!Validator.checkResponseData(response, error)) {
            this.setState({ error: error });
            window.scrollTo({ top: 0, behavior: "smooth" });
        } else {
            window.scrollTo({ top: 0, behavior: "smooth" });
            this.redirectWithMessage(this.props, "/main/programs/programview/" + programid, "Script Generated!")
        }

    }

    onClickGenerateScript = (programid) => {
        this.generatescript(programid, Startup.ENV)
    };

    handleButtonClick = () => {

        this.props.history.goBack();
    }

    onClickViewprogramCertifcate = (corporateid, programid) => {
        let env = Startup.ENV + ".";
        console.log(env)
        if (env == "local.")
            env = "dev.";
        else if (env == "prod.")
            env = "";

        let url = "https://content.trustedroads.com/plugin/popupshell.html?corporateid=" + corporateid + "&programid=" + programid + "&ccenv=" + env;
        window.open(url, '_blank').focus();
    };

    showProgramcode = (corporateid, programid) => {
        this.setState({ programid: programid, corporateid: corporateid })
    };

    getJavascriptCode() {

        if (this.state.programid && this.state.corporateid)
            return "<script id='trustedroadsscript' \n" +
                "src='https://content.trustedroads.com/plugin/trustedroads.js'\n" +
                "data-websiteid=" + this.state.programid + "\n" +
                "data-corporateid=" + this.state.corporateid + "\n" +
                "data-siterating-config='before,ID_VALUE'>\n" +
                "</script>\n";
        return "";
    }

    getJavascriptCustomCode() {

        if (this.state.programid && this.state.corporateid)
            return "<script id='trustedroadsscript' src='https://content.trustedroads.com/plugin/trustedroads.js' data-websiteid=" + this.state.programid +
                "&nbsp;data-corporateid=" + this.state.corporateid + "></script>\n" +
                "<img id='trustedroadsbannerid' src='https://content.trustedroads.com/plugin/images/reputation.png' height='50px'" +
                "width='200px' style='cursor:pointer; margin-bottom: 10px; margin-top: 10px;' onclick='trustedroadsshowpopup()'>";
        return "";
    }

    getTesterCode() {

        if (this.state.programid && this.state.corporateid)
            return "(function() {\n" +
                "var s = document.createElement('script');\n " +
                "s.type = 'text/javascript';\n" +
                "s.id = 'trustedroadsscript';\n " +
                "s.src = 'https://content.trustedroads.com/plugin/trustedroads.js';\n" +
                "s.setAttribute('data-websiteid','" + this.state.programid + "');\n" +
                "s.setAttribute('data-corporateid','" + this.state.corporateid + "');\n" +
                "s.setAttribute('data-siterating-config','before,ID_VALUE,id');\n " +
                "var fs = document.getElementsByTagName('script')[0];\n" +
                "fs.parentNode.insertBefore(s, fs);\n" +
                "})();\n";
        return "";
    }

    getModal() {
        return (
            <div className="modal fade" id="exampleModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">{this.state.programid}</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div><label className="propertyname">Javascript: </label></div>
                            <div dangerouslySetInnerHTML={{
                                __html: "<textarea disabled style='height: 150px; width: 450px;'>" + this.getJavascriptCode() + "</textarea>"
                            }}></div>
                            <div><label className="propertyname">Javascript (Custom Integration): </label></div>
                            <div dangerouslySetInnerHTML={{
                                __html: "<textarea disabled style='height: 150px; width: 450px;'>" + this.getJavascriptCustomCode() + "</textarea>"
                            }}></div>
                            <div><label className="propertyname">Tester Code: </label></div>
                            <div dangerouslySetInnerHTML={{
                                __html: "<textarea disabled style='height: 150px; width: 450px;'>" + this.getTesterCode() + "</textarea>"
                            }}></div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    render() {
        return (
            <div className="col p0 m0 mw700">

                {this.getMessagePanel()}
                <div className="container mw99p mt10 ml0 mr10 mb0 pt0 pl0 pr0 pb10 bglightgray">
                    <div className="row m0 pt5 pl0 pr0 pb0">
                        <div className="col-12"><span className="propertyname">Website Details:</span></div>
                    </div>

                    <div className="row m0 pt5 pl0 pr0 pb0">
                        <div className="col-12"><span className="propertyvalue"> Id: {this.state.program.programid && this.state.program.programid}</span></div>
                        <div className="col-12"><span className="propertyvalue"> Domain: {this.state.program.domain && this.state.program.domain}</span></div>
                        <div className="col-12"><span className="propertyvalue"><a href={this.state.program.termlink} target="_blank"><u>Privacy Page Link</u></a></span></div>
                    </div>

                    <div className="row m0 pt5 pl0 pr0 pb0">
                        <div className="col-12"><span className="propertyname">DSAR link/email</span></div>
                        <div className="col-12"><span className=" propertyvalue prewrap">{this.state.program.dsrinfo && this.state.program.dsrinfo}</span></div>
                    </div>

                    <div className="row m0 pt5 pl0 pr0 pb0">
                        <div className="col-12"><span className="propertyname">Reputation Details:</span></div>
                    </div>

                    <div className="row m0 pt5 pl0 pr0 pb0">
                        <div className="col-12"><span className="propertyvalue"> Rating Stars: {this.state.program.startrating && this.state.program.startrating}</span></div>
                        <div className="col-12"><span className="propertyvalue"> New Issues: {this.state.program.new && this.state.program.new}</span></div>
                        <div className="col-12"><span className="propertyvalue"> Pending Issues : {this.state.program.pending && this.state.program.pending}</span></div>
                        <div className="col-12"><span className="propertyvalue"> Due : {this.state.program.due && this.state.program.due}</span></div>
                        <div className="col-12"><span className="propertyvalue"> Closed Unresolved Issues: {this.state.program.closeunresolved && this.state.program.closeunresolved}</span></div>
                        <div className="col-12"><span className="propertyvalue"> Closed Resolved Issues : {this.state.program.closeresolved && this.state.program.closeresolved}</span></div>
                        <div className="col-12"><span className="propertyvalue"> Closed At Fault Issues : {this.state.program.closefault && this.state.program.closefault}</span></div>
                        <div className="col-12"><span className="propertyvalue"> Fault Issues (last 12 months) : {this.state.program.ratingvalid && this.state.program.ratingvalid}</span></div>
                        <div className="col-12"><span style={{ color: "blue", cursor: "pointer" }} onClick={() => this.onClickViewprogramCertifcate(this.state.program.corporateid, this.state.program.programid)} className="propertyvalue"><u>Program Reputation</u></span></div>
                        <div data-toggle="modal" data-target="#exampleModal" className="col-12"><span style={{ color: "blue", cursor: "pointer" }} onClick={() => this.showProgramcode(this.state.program.corporateid, this.state.program.programid)} className="propertyvalue"><u>Program Code</u></span></div>
                    </div>
                </div>


                <div className="row hc vc mt10 ml10 mr10">
                    <button
                        onClick={this.handleButtonClick}
                        type="button"
                        className=" btn btn-primary">
                        Back
                    </button>
                </div>
                {this.getModal()}
            </div >

        );
    }
}

/*
{UserSession.isAdmin() &&
                        <div className="row m0 pt5 pl0 pr0 pb5">
                            <div className="col-12"><span style={{ color: "blue", cursor: "pointer" }} className=" propertyvalue clickablelink" onClick={() => this.onClickGenerateScript(this.state.program.programid)}>Generate Script</span></div>
                        </div>
                    }
                    {this.state.links &&

                        <div className="row m0 pt5 pl0 pr0 pb0">
                            <div className="col-12"><span className="propertyname">Script Links:</span></div>
                            <div className="col-12">
                                <div><span className="propertyvalue"><a href={this.state.links.main} target="_blank"><u>Main script</u></a></span></div>
                                <div><span className="propertyvalue"><a href={this.state.links.submit} target="_blank"><u>Submit script</u></a></span></div>
                            </div>
                        </div>
                    }
*/