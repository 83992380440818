import React from "react";
import { UserSession } from "./UserSession";
import { log } from "../core/Util";
import { DBConstants } from "./Constants";

export class CoreComponent extends React.Component {

  isDesktop = true;
  animation = false;
  addedMobileListener = false;

  constructor() {
    super();
    this.setDeviceType()
    this.state = { animation: false, isDesktop: this.isDesktop, error: {} };
    

  }

  showAnimation() {
    this.animation = true;
    this.setState({ animation: this.animation });
  }

  closeAnimation() {
    this.animation = false;
    this.setState({ animation: this.animation });
  }

  componentWillUnmount() {
    if (this.addedMobileListener)
      this.removeDeviceTypeListener()
  }

  handleWindowSizeChangeEvent = () => {
    if (window.innerWidth < 720) this.isDesktop = false;
    else this.isDesktop = true;
    this.setState({ isDesktop: this.isDesktop });
  }

  setDeviceType() {
    if (window.innerWidth < 720)
      this.isDesktop = false;
    else
      this.isDesktop = true;
  }

  addDeviceTypeListener() {
    this.addedMobileListener = true;
    window.addEventListener("resize", this.handleWindowSizeChangeEvent);
  }

  removeDeviceTypeListener() {
    window.removeEventListener("resize", this.handleWindowSizeChangeEvent);
  }

  hasDisplayMessage(props) {
    if (props.location.data && this.props.location.data.displaymessage)
      return true
    if (this.state.displaymessage)
      return true
  }

  getContentStr(msg) {
    return (<span dangerouslySetInnerHTML={{ __html: msg }} />)
  }

  getDisplayMessage(props) {

    if (props.location.data && this.props.location.data.displaymessage)
      return props.location.data.displaymessage
    else if (this.state.displaymessage)
      return this.state.displaymessage
    else return ""
  }
  redirect(props, path) {
    props.history.push(path);
  }

  redirectWithData(props, path, data) {
    props.history.push({
      pathname: path,
      data: data
    })
  }

  redirectWithFullPath(props, path, fullpath) {
    let data = {}
    data[DBConstants.FULL_PATH] = fullpath
    props.history.push({
      pathname: path,
      data: data
    })
  }

  redirectWithMessageAndData(props, path, message, data) {
    data.displaymessage = message
    props.history.push({
      pathname: path,
      data: data
    })
  }

  redirectWithMessage(props, path, message) {
    props.history.push({
      pathname: path,
      data: { "displaymessage": message }
    })
  }

  reloadToNewPage(url) {
    window.location.href = url
  }

  reload() {
    window.location.reload();
  }

  onclickCloseButton = () => {
    this.state.displaymessage = null
  }

  confirmDialog(){

    return (
      <div className="modal fade" id="confirmdialog" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">{this.state.dialog.title}</h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <p>{this.state.dialog.body}</p>
            </div>
            <div className="modal-footer">
            <button type="button" className="btn btn-secondary" data-dismiss="modal" >Cancel</button>
              <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={()=>this.dialogConfirmed(this.state.dialog.key)}>Ok</button>

            </div>
          </div>
        </div>
      </div>
    )
  }

  getMessagePanel() {

    return (<>
      {this.hasDisplayMessage(this.props) && (
        <div className="alert alert-success alert-dismissible fade show" role="alert">
          {this.getDisplayMessage(this.props)}
          {/*<button type="button" className="close" data-dismiss="alert" aria-label="Close">
            <span onClick={this.onclickCloseButton} aria-hidden="true">&times;</span>
      </button>*/}
        </div>)}
      {this.state.animation && <div className="spinner" />}
      {this.state.error.backenderror && (
        <div className="mt10 alert alert-danger">
          {this.state.error.backenderror}
        </div>
      )}
    </>)
  }

}
