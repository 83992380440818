import React from "react";
import "./css/App.css";
import "./css/Common.css";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { Main } from "./pages/Main";
import { Login } from "./pages/Login";
import { CoreComponent } from "./core/CoreComponent";
import { UserConsentAck } from "./pages/UserConsentAck";
import { Signup } from "./pages/Signup";
import { ForgotPwd } from "./pages/ForgotPwd";
import { SetupPwd } from "./pages/SetupPwd";
import { Resetpwd } from "./pages/Resetpwd";
import { SignupWithEmail } from "./pages/SignupWithEmail";
import { RegisterWithEmail } from "./pages/RegisterWithEmail";
import { SetupPwdWithEmail } from "./pages/SetupPwdWithEmail";
import { CustomerCert } from "./pages/CustomerCert";
import { OpenInquiry } from "./pages/OpenInquiry";
import { SearchInquiry } from "./pages/SearchInquiry";
import { InquiryForm } from "./pages/InquiryForm";
import { InquiryFormConfirmation } from "./pages/InquiryFormConfirmation";
import { UserInquiryAck } from "./pages/UserInquiryAck";
import { CloseInquiry } from "./pages/CloseInquiry"

export class App extends CoreComponent {
  constructor() {
    super();
  }

  render() {
    return (
      <div>
        <Router>
          <Switch>
            <Route path="/login" component={Login} />
            <Route path="/searchinquiry" component={SearchInquiry} />
            <Route path="/closeinquiry/:inquiryid/:enc_pin" component={CloseInquiry} />
            <Route path="/openinquiry/:programid" component={OpenInquiry} />
            <Route path="/inquiryform/:corporateid/:programid" component={InquiryForm} />
            <Route path="/inquiryformsubmitconfirm" component={InquiryFormConfirmation} />
            <Route path="/userinquiryack/:enc_inquiryid" component={UserInquiryAck} />
            <Route path="/main" component={Main} />
            <Route path="/forgotpwd" component={ForgotPwd} />
            <Route path="/setuppwd" component={SetupPwd} />
            <Route path="/setuppwdwithemail" component={SetupPwdWithEmail} />
            <Route path="/signup/:enc_email?/:info?" component={Signup} />
            <Route path="/resetpwd/:enc_email?/:info?" component={Resetpwd} />
            <Route path="/signupwithemail/" component={SignupWithEmail} />
            <Route path="/registerewithemail/" component={RegisterWithEmail} />
            <Route path="/userconsentack/:consentid?" component={UserConsentAck} />
            <Route path="/customercert/:corporateid/:programid" component={CustomerCert} />
            <Route path="/" component={Login} />
          </Switch>
        </Router>
      </div>
    );
  }
}
