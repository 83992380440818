import axios from "axios";
import { log } from "../core/Util";
import { ErrorMsg } from "./Constants";
import { UserSession } from "./UserSession";

export class Apiserver {
  static BASE_URL = "";
  static GET = 0;
  static POST = 1;
  static headersValue = "";

  static setToken(token) {
    Apiserver.headersValue = {
      "Access-Control-Allow-Origin": "*",
      Authorization: "Bearer " + token,
    };
  }

  static getUrl(methodName) {
    return Apiserver.BASE_URL + methodName;
  }
  static async getcall(url, isMethodName = true) {
    if (isMethodName) url = Apiserver.getUrl(url);
    let response = await axios
      .get(url)
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        log(err);
        return err.response;
      });
    return response;
  }

  static async postcall(url, data, isMethodName = true) {
    log(url)
    if (UserSession.isAdmin())
        UserSession.fillAccountdetails(data)
    log(data);
    if (isMethodName) url = Apiserver.getUrl(url);
    let axiosConfig = { headers: Apiserver.headersValue };

    let response = await axios
      .post(url, data, axiosConfig)
      .then((response) => {
        log(response.data);
        return response.data;
      })
      .catch((err) => {
        return {errorMessage:ErrorMsg.NETWORK_ERROR};
      });

    return response;
  }
}
