import React from "react";
import { CoreComponent } from "../core/CoreComponent";
import { Apiserver } from "../core/Apiserver";
import { log, Validator } from "../core/Util";
import { DBConstants, TextData } from "../core/Constants";
import { BannerBar } from "../core/MenuBar";
import { domainDisplay } from "../core/Util";

export class InquiryFormConfirmation extends CoreComponent {

  constructor(props) {
    super(props);

    if (props.location && props.location.data) {
      this.state.domain = props.location.data.domain
      this.state.inquiryid = props.location.data.inquiryid
      this.state.emailid = props.location.data.emailid
    }

  }

  resendEmail = () => {
    this.postdata()
  }

  sendMessageToSupport = () => {
    let subject = "Not receiving emails for Inquiry Id " + this.state.inquiryid
    window.location.href = "mailto:support@trustedroads.com?subject=" + subject;
  }

  async postdata() {

    var datain = {};
    datain[DBConstants.INQUIRY_ID] = this.state.inquiryid

    this.showAnimation();
    let response = await Apiserver.postcall(
      "resend_email_for_inquiry",
      datain
    );
    this.closeAnimation();

    let error = {};
    if (!Validator.checkResponseData(response, error)) {
      this.setState({ error: error })
      window.scrollTo({ top: 0, behavior: "smooth" });
    } else {
      this.setState({ "displaymessage": "Total email resend : " + response.data.retries, retries: response.data.retries })
    }
  }

  getText() {

    let displaystr = TextData.SUBMISSION_CONFIRMATION_PART.replace("{USER_EMAIL_ID}", this.state.emailid);
    displaystr = displaystr.replace("{WEBSITE_NAME}", domainDisplay(this.state.domain))
    return this.getContentStr(displaystr)
  }
  handleButtonClick = () => {
    window.location.href = "https://www.trustedroads.com"
  }

  render() {

    return (
      <div className="d-flex flex-row hc vc pl10 pr10 backimg">
        <div className="container col-md-6 col-s-12 fs15 m0 pl0 pr0 pt0 pb10 mw700">
          <img src={this.state.bannerlink && this.state.bannerlink} style={{ "width": "100%" }} />
          <div className="formborder pb20">
            <BannerBar />

            <div className="mt20 ml10 mr10">
              {this.getMessagePanel()}
              <label className="form-label">
                {this.getText()}
              </label>

              {(!this.state.retries || this.state.retries < 2) ?
                (<label>
                  <span
                    className="clickablelink fs12"
                    style={{ padding: "5px", margin: "0px" }}
                    onClick={() => this.resendEmail()}
                  >
                    You may click here to resend it again
                  </span>
                </label>) : (<label>
                  <span
                    className="clickablelink fs12"
                    style={{ padding: "5px", margin: "0px" }}
                    onClick={() => this.sendMessageToSupport()}
                  >
                    Inform Support
                  </span>
                </label>)}
            </div>

            <div className="d-flex flex-row hc vc mt20 ml10 mr10">
              <button
                onClick={this.handleButtonClick}
                type="button"
                className=" btn btn-primary"
              >
                Back to TrustedRoads website
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
