import React from "react";
import { CoreComponent } from "../core/CoreComponent";
import { Apiserver } from "../core/Apiserver";
import { DBConstants, TextData } from "../core/Constants";
import { log, addData, Validator, getValue } from "../core/Util";
import { BannerBar } from "../core/MenuBar";
import { domainDisplay } from "../core/Util";

export class InquiryForm extends CoreComponent {

  constructor() {
    super();
    this.state.model = {}
    this.state.show = false
  }

  componentDidMount() {
    this.getdata();
  }

  async getdata(datain) {
    var datain = {}
    datain[DBConstants.PROGRAM_ID] = this.props.match.params.programid;
    this.showAnimation();
    let response = await Apiserver.postcall("get_program_simple", datain);
    this.closeAnimation();
    let error = {};
    if (!Validator.checkResponseData(response, error)) {
      this.setState({ error: error });
      window.scrollTo({ top: 0, behavior: "smooth" });
    } else {
      this.setState({ program: response.data })
    }
  }

  async sendInfo() {
    console.log("sending info")
    var datain = {}
    datain["info"] = this.props.match.params.info
    let response = await Apiserver.postcall("sendemailinfo", datain);
  }

  handleButtonClick = () => {

    let error = {};
    Validator.checkEmailId(error, "emailid");
    Validator.checkValidOption(error, "resident");
    Validator.checkValidOption(error, "enquirytype");

    if (Validator.dict_size(error) != 0) {
      this.setState({ error: error })
      window.scrollTo({ top: 0, behavior: "smooth" });
    } else
      this.postdata();
  };

  async postdata() {

    var datain = {};
    datain[DBConstants.PROGRAM_ID] = this.props.match.params.programid;
    datain[DBConstants.Email_ID] = getValue("emailid");
    datain[DBConstants.INQUIRY_RESIDENT_OF] = getValue("resident");
    datain[DBConstants.INQUIRY_TYPE] = getValue("enquirytype");
    datain[DBConstants.INQUIRY_MORE_INFO] = getValue("moreinfo");

    this.showAnimation();
    let response = await Apiserver.postcall(
      "create_inquiry",
      datain
    );
    this.closeAnimation();

    let error = {};
    if (!Validator.checkResponseData(response, error)) {
      this.setState({ error: error })
      window.scrollTo({ top: 0, behavior: "smooth" });
    } else {
      let data = {}
      data[DBConstants.DOMAIN] = response.data.domain
      data[DBConstants.INQUIRY_ID] = response.data.inquiryid
      data[DBConstants.Email_ID] = response.data.emailid
      console.log(data)
      this.redirectWithData(this.props, "/inquiryformsubmitconfirm/", data)
    }
  }

  render() {

    return (
      <div className="d-flex flex-row justify-content-center backimg pt20 pl10 pr10 pb20">
        <div className="container col-lg-5 col-md-6 col-s-12 fs15 m0 pl0 pr0 pt0 pb10 mw700">
          <div className="formborder pb20">


            <BannerBar />

            <div className="mt20 ml20 mr20">
              {this.getMessagePanel()}

              <label htmlFor="username" className="row p0 m0 mt25 propertytitle form-label">
                Inquiry Form
              </label>

              <label className="form-label propertyvalue mt10">
                Create an Inquiry for <span className="propertyname">{this.state.program && domainDisplay(this.state.program.domain)}</span>
              </label>

              <div className="mt10">
                <label className="form-label propertyname">Email:</label>
                {this.state.error.emailid && (
                  <div className="alert alert-danger">{this.state.error.emailid}</div>
                )}
                <input
                  type="email"
                  className="form-control"
                  id="emailid"
                  placeholder="name@example.com"
                  size="5"
                />
              </div>
              <div className="mt20">
                <label className="form-label propertyname">Resident of:</label>
                {this.state.error.resident && (
                  <div className="alert alert-danger">{this.state.error.resident}</div>
                )}
                <select className="form-control" id="resident">
                  <option></option>
                  <option>California, US</option>
                  <option>Other US States</option>
                  <option>UK</option>
                  <option>European Union Member States</option>
                  <option>Australia</option>
                  <option>Others</option>
                </select>
              </div>
              <div className="mt20">
                <label className="form-label propertyname">Inquiry Type:</label>
                {this.state.error.enquirytype && (
                  <div className="alert alert-danger">{this.state.error.enquirytype}</div>
                )}
                <select className="form-control" id="enquirytype">
                  <option></option>
                  <option>Receiving spam messages after unsubscribing</option>
                  <option>Missuse of personal information</option>
                  <option>Others</option>
                </select>
              </div>
              <div className="mt20">
                <label className="form-label propertyname">More Information (optional):</label>
                {this.state.error.moreinfo && (
                  <div className="alert alert-danger">{this.state.error.moreinfo}</div>
                )}
                <textarea
                  className="form-control"
                  id="moreinfo"
                  rows="6"
                ></textarea>
              </div>

              <div className="d-flex flex-row justify-content-center mt20 mb20">
                <button
                  onClick={this.handleButtonClick}
                  type="button"
                  className=" btn btn-primary">
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
