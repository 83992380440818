import React from "react";
import { CoreComponent } from "../core/CoreComponent";
import { UserSession } from "../core/UserSession";
import { Apiserver } from "../core/Apiserver";
import { log, Validator, getDateFromDict, setValue, getValue, Startup } from "../core/Util";
import { Link } from "react-router-dom";
import { DBConstants } from "../core/Constants";
import * as MdIcons from "react-icons/md";
import * as BiIcons from "react-icons/bi";


export class CorporateList extends CoreComponent {

  constructor() {
    super();
    this.state.model = []
    this.state.links = {}
    this.state.title = ""
    UserSession.setToAdmin()
  }

  componentDidMount() {
    this.addDeviceTypeListener()
    if (!UserSession.isLoggedIn()) {
      this.props.history.push("/login");
    }
    else
      this.getdata();
  }

  async getdata() {
    var datain = {}
    datain[DBConstants.ACCOUNT_IDENTIFIER] = UserSession.account.identifier
    this.showAnimation();
    let response = await Apiserver.postcall("get_corporate_list", datain);
    this.closeAnimation();

    let error = {};
    if (!Validator.checkResponseData(response, error)) {
      this.setState({ error: error });
      window.scrollTo({ top: 0, behavior: "smooth" });
    } else {
      this.setState({ model: response.data.list })
    }
  }

  mappingData(list) {

    for (let item of list) {
      item.link = "Link2";
    }
    return list;
  }

  onClickAccountView(corporateid) {
    UserSession.setToCorporateId(corporateid)
    this.redirect(this.props, "/main/programs")
  };

  onClickView = (corporateid, corporatename) => {
    this.getlinksdata(corporateid, corporatename)
  };

  async getlinksdata(corporateid, corporatename) {
    var datain = {}
    datain[DBConstants.Corporate_ID] = corporateid

    this.showAnimation();
    let response = await Apiserver.postcall("getLinks", datain);
    this.closeAnimation();

    let error = {};
    if (!Validator.checkResponseData(response, error)) {
      this.setState({ error: error });
      window.scrollTo({ top: 0, behavior: "smooth" });
    } else {
      this.setState({ links: response.data, title: corporatename })
    }


  }

  getModal() {
    return (
      <div className="modal fade" id="exampleModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">{this.state.title}</h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <label className="propertyname">Signup Link: </label><label className="wrap">{this.state.links.signuplink && this.state.links.signuplink}</label>
              {/*this.state.links.programlinklist && Object.keys(this.state.links.programlinklist).map((item, index) => (
                <div key={index} >
                  <label className="propertyname">{this.state.links.programlinklist[item].domain}:</label>
                  <label className="wrap">
                    {this.state.links.programlinklist[item].link}</label>
                </div>
              ))*/}
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
            </div>
          </div>
        </div>
      </div>
    )
  }

  refresh = () => {
    this.reload()
  };

  getDesktopRender() {
    return (
      <div className="container m0 p0">
        {this.getMessagePanel()}
        <div className="d-flex flex-row-reverse mt10 ml10 mr10 mb10" onClick={this.refresh}>
          <button onClick={this.refresh} type="button" className=" btn btn-primary">Refresh</button>
        </div>
        <table className="col-12 fs17 table table-striped">
          <thead className="bgtable">
            <tr>
              <th scope="col">Corporate name</th>
              <th scope="col">Corporate Id</th>
              <th scope="col">Date Created</th>
              <th scope="col">Links</th>
            </tr>
          </thead>
          <tbody>
            {this.state.model.map((item, index) => (
              <tr key={index}>
                <td className="clickablelink" onClick={() => this.onClickAccountView(item.corporateid)}>{item.corporatename}</td>
                <td>{item.corporateid}</td>
                <td>{getDateFromDict(item.date)}</td>
                <td className="m0 p0">
                  <div data-toggle="modal" data-target="#exampleModal" onClick={() => this.onClickView(item.corporateid, item.corporatename)} className="icon ml10">
                    <BiIcons.BiLink />
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        {this.getModal()}
      </div>
    );
  }

  getMobileRender() {
    return (
      <div className="col p0 m0 mw700">
        {this.getMessagePanel()}
        <div className="d-flex flex-row-reverse mt10 ml10 mr10 mb10" onClick={this.refresh}>
          <button onClick={this.refresh} type="button" className=" btn btn-primary">Refresh</button>
        </div>
        {this.state.model.map((item, index) => (
          <div key={index} className="container mw99p mt10 ml0 mr10 mb0 p0 bglightgray">
            <div className="row m0 pt5 pl0 pr0 pb0">
              <div className="col-12"><span className="propertyname">Corporate name:</span></div>
              <div className="col-12"><span className="propertyvalue clickablelink" onClick={() => this.onClickAccountView(item.corporateid)}>{item.corporatename}</span></div>
            </div>
            <div className="row m0 pt5 pl0 pr0 pb0">
              <div className="col-12"><span className="propertyname">Corporate Id:</span></div>
              <div className="col-12"><span className="propertyvalue">{item.corporateid}</span></div>
            </div>
            <div className="row m0 pt5 pl0 pr0 pb0">
              <div className="col-12"><span className="propertyname">Date Created:</span></div>
              <div className="col-12"><span className=" propertyvalue">{getDateFromDict(item.date)}</span></div>
            </div>
            <div className="row m0 pt5 pl0 pr0 pb0">
              <div className="col-12"><span className="propertyname">Links:</span></div>
              <div className="col-12"><div data-toggle="modal" data-target="#exampleModal" onClick={() => this.onClickView(item.corporateid, item.corporatename)} className="icon ml10">
                <BiIcons.BiLink />
              </div></div>
            </div>
          </div>
        ))}
        {this.getModal()}
      </div>
    );
  }
  render() {
    return (<>{this.isDesktop ? this.getDesktopRender() : this.getMobileRender()}</>);
  }
}

