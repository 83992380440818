import React from "react";
import { CoreComponent } from "../core/CoreComponent";
import { UserSession } from "../core/UserSession";
import { Apiserver } from "../core/Apiserver";
import { log, Validator, getDateFromDict, getValue } from "../core/Util";
import { DBConstants } from "../core/Constants";
import { BannerBar } from "../core/MenuBar";

export class Login extends CoreComponent {

  fullPathavliable = false
  path = "";
  constructor(props) {
    super(props);
    //this.state.displaymessage="Test msg" TODO 
    console.log(props.location.data)
    if (props.location.data && props.location.data.fullpath) {
      this.fullPathavliable = true;
      this.path = props.location.data.fullpath;
    }
  }

  componentDidMount() {

    if (UserSession.isLoggedIn()) {
      if (UserSession.isAdmin())
        this.props.history.push("/main/corporatelist");
      else
        this.props.history.push("/main/programs");
    }
    else {

      if (this.path.startsWith("/main/consents/userconsentviewlink/")) {
        let consentid = this.path.substring(this.path.lastIndexOf("/") + 1)
        this.getAccountSetupStatus(consentid)
      }
      else {
        this.addKeyupEventListener();
        if (this.props.location.data && this.props.location.data.emailid)
          this.setState({ emailid: this.props.location.data.emailid })
      }
    }
  }

  addKeyupEventListener() {
    document.getElementById("password").addEventListener("keyup", function (event) {
      if (event.keyCode === 13) {
        event.preventDefault();
        document.getElementById("login").click();
      }
    });
  }

  async getAccountSetupStatus(consentid) {

    var datain = {}
    datain[DBConstants.CONSENT_ID] = consentid
    this.showAnimation();
    let response = await Apiserver.postcall("get_user_account_setup", datain);
    this.closeAnimation();


    let error = {};
    if (!Validator.checkResponseData(response, error)) {
      this.setState({ error: error });
      this.reload();// this should go to error page
    } else {
      if (response.data[DBConstants.PWD_STATUS] == DBConstants.PWD_NOT_SET) {
        this.redirect(this.props, "/signup/" + response.data[DBConstants.SIGN_UP_LINK_PARAMETER]);
      }
      else
        this.addKeyupEventListener();
    }
  }

  forgotpwd = () => {
    this.props.history.push("/forgotpwd");
  }

  register = () => {
    this.props.history.push("/registerewithemail");
  }

  handleButtonClick = () => {

    let error = {};
    Validator.checkEmailId(error, "email");
    Validator.checkValidString(error, "password");
    if (Validator.dict_size(error) != 0) {
      this.setState({ error: error })

    } else {
      this.postdata()
    }
  }

  async postdata() {
    let datain = {}
    datain[DBConstants.Email_ID] = getValue("email");
    datain[DBConstants.PWD] = getValue("password");

    this.showAnimation();
    let response = await Apiserver.postcall(
      "login",
      datain
    );
    this.closeAnimation();

    let error = {};
    if (!Validator.checkResponseData(response, error)) {
      this.setState({ error: error })
      window.scrollTo({ top: 0, behavior: "smooth" });
    } else {

      UserSession.account.id = response.data.id
      UserSession.account.token = response.data.token
      UserSession.account.identifier = response.data.identifier
      UserSession.account.accounttype = response.data.accounttype
      UserSession.saveUserinfo()
      if (this.fullPathavliable) {
        this.redirect(this.props, this.props.location.data.fullpath)
      }
      else {
        if (UserSession.isAdmin())
          this.props.history.push("/main/corporatelist");
        else
          this.props.history.push("/main/programs");
      }
    }
  }




  render() {

    return (
      <div className="d-flex flex-row m0 p0 hfull backimg justify-content-center">
        <div className="col col-lg-5 col-md-6 col-s-12 fs15 m20 mt30 p0 formborder justify-content-center mh500">
          <BannerBar />
          <div>
            <label htmlFor="username" className="row p0 m0 mt25 ml10 propertytitle form-label">
              Login
            </label>
          </div>
          <div className="mt20 ml10 mr10">
            <div id="msgpanel">
              {this.getMessagePanel()}
            </div>
            <label htmlFor="username" className="propertyname form-label">
              Email id:
            </label>
            {this.state.error.email && (
              <div className="alert alert-danger">{this.state.error.email}</div>
            )}
            <input
              type="email"
              className="form-control"
              id="email"
              size="5"
              value={this.state.emailid}
            />
          </div>
          <div className="mt20 ml10 mr10">
            <label htmlFor="password" className="propertyname form-label">
              Password:
            </label>
            {this.state.error.password && (
              <div className="alert alert-danger">{this.state.error.password}</div>
            )}
            <input
              type="password"
              className="form-control"
              id="password"
              size="5"
            />
          </div>
          <div className="row mt20 ml10 mr10">
            <label className="form-label linklabel" onClick={this.forgotpwd}>Forgot Password</label>
            <label className="form-label linklabel ml20" onClick={this.register}>Register</label>
          </div>
          <div className="d-flex flex-row hc vc mt20 ml10 mr10 ">
            <button id="login"
              onClick={this.handleButtonClick}
              type="button"
              className=" btn btn-primary"
            >
              Login
            </button>
          </div>
        </div>
      </div>
    );
  }
}
