import React from "react";
import { CoreComponent } from "../core/CoreComponent";
import { Apiserver } from "../core/Apiserver";
import { log, Validator, getValue } from "../core/Util";
import { DBConstants, ErrorMsg } from "../core/Constants";
import { BannerBar } from "../core/MenuBar";

export class SetupPwd extends CoreComponent {

  constructor(props) {
    super(props);

    if (props.location && props.location.data) {
      this.state.email = props.location.data.emailid
    }

  }

  handleButtonClick = () => {
    this.props.history.push("/login");
  }

  resendEmail = () => {
    this.postdata()
  }

  async postdata() {

    let datain = {}
    datain[DBConstants.Email_ID] = this.state.email
    datain[DBConstants.SIGNUP_LINK_TYPE] = this.props.location.data[DBConstants.SIGNUP_LINK_TYPE]

    this.showAnimation();
    let response = await Apiserver.postcall("send_signup_link", datain);
    this.closeAnimation();

    let error = {};
    if (!Validator.checkResponseData(response, error)) {
      this.setState({ error: error });
      window.scrollTo({ top: 0, behavior: "smooth" });
    } else {
      this.redirectWithMessageAndData(this.props, "/setuppwd", "Email link resent again!", datain)
    }

  }

  render() {
    return (
      <div className="d-flex flex-row m0 p0 hfull backimg justify-content-center">
        <div className="col col-lg-5 col-md-6 col-s-12 fs15 m20 mt30 p0 formborder justify-content-center mh500">
          <BannerBar />
          <div>
            <label htmlFor="username" className="row p0 m0 mt25 ml10 propertytitle form-label">
              Access link Sent !
          </label>
          </div>
          <div className="mt20 ml10 mr10">
            {this.getMessagePanel()}
            <label htmlFor="username" className="form-label">
              We have emailed (<span className="fcmaroon">{this.state.email && this.state.email}</span>) you a link to setup a new password. If you haven’t received your email please check your junk/spam folder.
            </label>
            <label>
              <span
                className="clickablelink fs12"
                style={{ padding: "5px", margin: "0px" }}
                onClick={() => this.resendEmail()}
              >
                You may click here to resend it again.
              </span>
            </label>
          </div>
          <div className="d-flex flex-row hc vc mt20 ml10 mr10">
            <button
              onClick={this.handleButtonClick}
              type="button"
              className=" btn btn-primary"
            >
              Back To Login
            </button>
          </div>
        </div>
      </div>
    );
  }
}
