import React from "react";
import { CoreComponent } from "../core/CoreComponent";
import { UserSession } from "../core/UserSession";
import { Apiserver } from "../core/Apiserver";
import { DBConstants, TextData } from "../core/Constants";
import { log, addData, Validator } from "../core/Util";
import { BannerBar } from "../core/MenuBar";

export class UserConsentAck extends CoreComponent {

  constructor() {
    super();
    this.state.model = {}
  }

  componentDidMount() {
    this.postdata();
  }
  async postdata() {

    let data = {}
    data[DBConstants.CONSENT_ID] = this.props.match.params.consentid

    this.showAnimation();
    let response = await Apiserver.postcall("user_consent_ack", data);
    this.closeAnimation();

    let error = {};
    if (!Validator.checkResponseData(response, error)) {
      this.setState({ error: error });
      window.scrollTo({ top: 0, behavior: "smooth" });
    } else {
      let model = {}
      model.corporatename = response.data.corporatename;
      try {
        model.email = atob(response.data.useremailid);
      }
      catch (err) {
        console.log(err)
        console.log(response.data.useremailid)
        model.email = ""
      }
      model.enc_email = response.data.useremailid
      model.backlink = response.data.backlink;
      model.bannerlink = response.data.bannerlink;
      model.pwdstatus = response.data.pwdstatus
      if (model.pwdstatus == DBConstants.PWD_NOT_SET) {
        model.info = response.data.info

      }
      this.setState({ model: model })
    }
  }

  getText() {
    let displaystr = TextData.USER_CONSENT_ACK_PART.replace("{CORPORATE}", this.state.model.corporatename);
    displaystr = displaystr.replace("{USER_EMAIL_ID}", this.state.model.email);
    return this.getContentStr(displaystr)
  }

  signup = () => {

    if (this.state.model.pwdstatus == DBConstants.PWD_NOT_SET)
      this.redirect(this.props, "/Signup/" + this.state.model.enc_email + "/" + this.state.model.info)
    else
      this.redirect(this.props, "/login")
  }

  handleRetryClick = () => {
    window.location.reload();
    //this.props.history.push("/user_enquiry_ack/" + this.props.match.params.id);
  };

  render() {
    return (
      <div className="d-flex flex-row justify-content-center hfull backimg pt20 pl10 pr10 pb20">
        <div className="container col-lg-5 col-md-6 col-s-12 fs15 m0 pl0 pr0 pt0 pb10 mw700">
          <img src={this.state.model.bannerlink && this.state.model.bannerlink} style={{ "width": "100%" }} />
          <div className="formborder pb20">
            <BannerBar />

            <div className="mt20 ml10 mr10">
              {this.getMessagePanel()}
              <label className="form-label">
                {this.getText()}
              </label>
            </div>

            <div className="row hc vc mt20 ml10 mr10">
              {this.state.error.backenderror ? (
                <button onClick={this.handleRetryClick} type="button" className=" btn btn-primary" >
                  Retry
                </button>
              ) : (
                <button onClick={this.signup} type="button" className=" btn btn-primary" >
                  {this.state.model.pwdstatus && (this.state.model.pwdstatus == DBConstants.PWD_NOT_SET ? ("Sign-up") : ("Login"))}
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
