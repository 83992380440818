import React from "react";
import { CoreComponent } from "../core/CoreComponent";
import { Apiserver } from "../core/Apiserver";
import { DBConstants, TextData } from "../core/Constants";
import { log, addData, Validator } from "../core/Util";
import { BannerBar } from "../core/MenuBar";


export class CloseInquiry extends CoreComponent {
  constructor() {
    super();
    this.state.model = {}
  }

  componentDidMount() {
    this.postdata();
  }
  async postdata() {

    let data = {}
    data[DBConstants.INQUIRY_ID] = this.props.match.params.inquiryid
    data[DBConstants.INQUIRY_PIN] = this.props.match.params.enc_pin

    this.showAnimation();
    let response = await Apiserver.postcall("close_resolved_inquiry", data);
    this.closeAnimation();

    let error = {};
    if (!Validator.checkResponseData(response, error)) {
      this.setState({ error: error })
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
    else {
      this.setState({ ackdata: response.data })
    }
  }

  getText() {
    if (this.state.ackdata) {
      let displaystr = TextData.USER_INQUIRY_CLOSED.replace("{USER_EMAIL_ID}", this.state.ackdata.emailid);
      return this.getContentStr(displaystr);
    }
    else {
      return "";
    }
  }
  handleButtonClick = () => {
    window.location.href = "https://www.trustedroads.com"
  }

  render() {

    return (
      <div className="d-flex flex-row justify-content-center backimg pt20 pl10 pr10 pb20">
        <div className="container col-md-6 col-s-12 fs15 m0 pl0 pr0 pt0 pb10 mw700">
          <img src={this.state.bannerlink && this.state.bannerlink} style={{ "width": "100%" }} />
          <div className="formborder pb20">
            <BannerBar />

            <div className="mt20 ml10 mr10">
              {this.getMessagePanel()}
              <label className="form-label">
                {this.getText()}
              </label>
            </div>

            <div className="d-flex flex-row hc vc mt20 ml10 mr10">
              <button
                onClick={this.handleButtonClick}
                type="button"
                className=" btn btn-primary"
              >
                Back to TrustedRoads website
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

