import React from "react";
import { CoreComponent } from "../core/CoreComponent";
import { UserSession } from "../core/UserSession";
import { Apiserver } from "../core/Apiserver";
import { log, Validator, getDateFromDict } from "../core/Util";
import { DBConstants, TextData } from "../core/Constants";

export class Consent extends CoreComponent {

  constructor() {
    super();
    this.state.model = []
    this.state.dialog = {}
  }

  componentDidMount() {
    this.addDeviceTypeListener()
    if (UserSession.isLoggedIn()) {
      this.getdata();
    }
  }

  async getdata() {
    var datain = {}
    this.showAnimation();
    let response = await Apiserver.postcall("get_consent_list", datain);
    this.closeAnimation();

    let error = {};
    if (!Validator.checkResponseData(response, error)) {
      this.setState({ error: error });
      window.scrollTo({ top: 0, behavior: "smooth" });
    } else {
      let list = this.mappingData(response.data.list)
      if (response.data.list.length == 0)
        this.setState({ model: response.data.list, "displaymessage": "You don't have any Consents" })
      else
        this.setState({ model: response.data.list })
    }
  }

  mappingData(list) {

    for (let item of list) {
      /*if (item.status == DBConstants.ENQUIRY_STATUS_ACK_NOT_COMPLETE){
        item.status = "Ack. Not Complete";
        if(UserSession.isUser())
          item.status = "Acknowledge";
      }*/
    }
    return list;
  }

  onClickAck = (consentid) => {
    this.postdata(consentid)
  }

  async postdata(consentid) {

    let data = {}
    data[DBConstants.CONSENT_ID] = consentid

    this.showAnimation();
    let response = await Apiserver.postcall("user_consent_ack", data);
    this.closeAnimation();

    let error = {};
    if (!Validator.checkResponseData(response, error)) {
      this.setState({ error: error });
      window.scrollTo({ top: 0, behavior: "smooth" });
    } else {
      this.redirectWithMessage(this.props, '/main/consents', TextData.CONSENT_ACK)
    }
  }

  onClickView = (consentid) => {
    this.props.history.push("/main/consents/consentview/" + consentid);
  };

  onRevokeshwoconfirmdialog(corporatename,useremailid,consentid) {
    let title="Revoke Consent ";
    let body="Are you sure, you would like to revoke your consent ("+consentid+") given to "+corporatename+" to keep your PII data  ?";

    if(UserSession.isCorporate()){
      title="Delete Consent "
      body="Are you sure, you would like to delete consent ("+consentid+") given by "+useremailid+" to keep his/her PII data  ?";
    }
    this.setState({
      dialog: {
        title: title,
        body: body,
        key: consentid
      }
    })
  }

  dialogConfirmed = (consentid) => {
    this.revokeconsent(consentid);
  }

  async revokeconsent(consentid) {

    let data = {}
    data[DBConstants.CONSENT_ID] = consentid

    this.showAnimation();
    let response={}
    if(UserSession.isCorporate())
      response = await Apiserver.postcall("delete_consent", data);
    else
      response = await Apiserver.postcall("opt_out_consent", data);
    this.closeAnimation();

    let error = {};
    if (!Validator.checkResponseData(response, error)) {
      this.setState({ error: error });
      window.scrollTo({ top: 0, behavior: "smooth" });
    } else {
      this.reload();
    }
  }


  refresh = () => {
    this.reload()
  };

  getDesktopRender() {
    return (
      <div className="container m0 p0">
        {this.getMessagePanel()}
        <div className="d-flex flex-row-reverse mt10 ml10 mr10 mb10" onClick={this.refresh}>
          <button onClick={this.refresh} type="button" className=" btn btn-primary">Refresh</button>
        </div>
        <table className="col-12 fs17 table">
          <thead className="bgtable">
            <tr>
              <th scope="col">{UserSession.isCorporate() ? "User" : "Corporate"}</th>
              <th scope="col">Date Created </th>
              <th scope="col">Action</th>
              <th scope="col"></th>
            </tr>
          </thead>
          <tbody>
            {this.state.model.map((item, index) => (
              <tr key={index}>
                <td>{UserSession.isCorporate() ? item.useremailid : item.corporatename}</td>
                <td>{getDateFromDict(item.date)}</td>
                <td className="m0 p0">
                  <div className="d-flex flex-row m0 p0" >
                    <div style={{ "marginTop": "12px", "marginLeft":"10px",color: "blue", cursor: "pointer" }}>
                      <span onClick={() => this.onClickView(item.consentid)} ><u>View</u></span>
                      { /*item.status==DBConstants.STATUS_ACTIVE && 
                        <span data-toggle="modal" data-target="#confirmdialog" onClick={() => this.onRevokeshwoconfirmdialog(item.corporatename,item.useremailid,item.consentid)} ><u>{UserSession.isCorporate() ? "Delete Consent": "Opt-out"}</u></span>
                      */}
                      </div>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        {this.confirmDialog()}
      </div>
    );
  }


  getMobileRender() {

    return (
      <div className="col p0 m0 mw700">
        {this.getMessagePanel()}
        <div className="d-flex flex-row-reverse mt10 ml10 mr10 mb10" onClick={this.refresh}>
          <button onClick={this.refresh} type="button" className=" btn btn-primary">Refresh</button>
        </div>
        {this.state.model.map((item, index) => (
          <div key={index} className="container mw99p mt10 ml0 mr10 mb0 p0 bglightgray">
            <div className="row m0 pt5 pl0 pr0 pb0">
              <div className="col-12"><span className="propertyname">User Info:</span></div>
              <div className="col-12"><span className="propertyvalue">{UserSession.isCorporate() ? item.useremailid : item.corporatename}</span></div>
            </div>
            <div className="row m0 pt5 pl0 pr0 pb0">
              <div className="col-12"><span className="propertyname">Date Created:</span></div>
              <div className="col-12"><span className="propertyvalue">{getDateFromDict(item.date)}</span></div>
            </div>
            <div className="d-flex flex-row" >
              <div onClick={() => this.onClickView(item.consentid)} 
                style={{"marginLeft":"15px","marginRight":"5px","marginTop": "5px", "marginBottom":"10px", color: "blue", cursor: "pointer" }}>
                <u>View</u>
              </div>
              {/*item.status==DBConstants.STATUS_ACTIVE &&
              <div onClick={() => this.onRevokeshwoconfirmdialog(item.corporatename,item.consentid)} 
                style={{ "marginLeft":"15px", "marginRight":"5px", "marginTop": "5px", "marginBottom":"10px", color: "blue", cursor: "pointer" }}>
                <u>{UserSession.isCorporate() ? "Delete Consent": "Revoke"}</u>
              </div>*/}
            </div>
          </div>
        ))}
        {this.confirmDialog()}
      </div>
    );
  }
  render() {
    return (<>{this.isDesktop ? this.getDesktopRender() : this.getMobileRender()}</>);
  }
}

