import React from "react";
import { CoreComponent } from "../core/CoreComponent";
import { UserSession } from "../core/UserSession";
import { Apiserver } from "../core/Apiserver";
import { log, Validator, getValue } from "../core/Util";
import { DBConstants, ErrorMsg } from "../core/Constants";

export class ChangePwd extends CoreComponent {

  constructor() {
    super();
  }

  cancel = () => {
    this.redirect(this.props, '/main/corporate/')
  }

  changepwd = () => {

    let error = {};
    Validator.checkValidString(error, "password");
    Validator.checkValidString(error, "password1");
    Validator.checkValidString(error, "password2");

    if (Validator.dict_size(error) == 0) {
      let value1 = getValue("password1");
      let value2 = getValue("password2")
      if (value1 != value2)
        error["password1"] = ErrorMsg.PWD_MIS_MATCH;
    }

    if (Validator.dict_size(error) != 0) {
      this.setState({ error: error })
    } else
      this.postdata()
  };

  async postdata() {

    let datain = {}
    datain[DBConstants.OLD_PWD] = getValue("password")
    datain[DBConstants.NEW_PWD] = getValue("password2")

    this.showAnimation();
    let response = await Apiserver.postcall("change_password", datain);
    this.closeAnimation();

    let error = {};
    if (!Validator.checkResponseData(response, error)) {
      this.setState({ error: error });
      window.scrollTo({ top: 0, behavior: "smooth" });
    } else {
      this.redirectWithMessage(this.props, "/main/corporate", "Password Changed Successfully!")
    }

  }

  render() {
    return (
      <div className="d-flex flex-row mt20 pl10 pr10 ">
        <div className="container col-md-6 col-s-12 fs15 m0 pl0 pr0 pt0 pb10 mw500">

          <div className="mt20 ml10 mr10">
            {this.getMessagePanel()}

            {this.state.error.password && (
              <div className="alert alert-danger mt10">{this.state.error.password}</div>
            )}
            <label htmlFor="username" className="form-label">
              Old password:
            </label>

            <input
              type="password"
              className="form-control"
              id="password"
              size="5"
            />
            {this.state.error.password1 && (
              <div className="alert alert-danger mt10">{this.state.error.password1}</div>
            )}
            <label htmlFor="username" className="form-label mt10">
              New password:
            </label>
            <input
              type="password"
              className="form-control mt10"
              id="password1"
              size="5"
            />
            {this.state.error.password2 && (
              <div className="alert alert-danger mt10">{this.state.error.password2}</div>
            )}
            <label htmlFor="username" className="form-label mt10">
              Re-Enter New password:
            </label>
            <input
              type="password"
              className="form-control mt10"
              id="password2"
              size="5"
            />
          </div>
          <div className="d-flex flex-row hc vc mt20 ml10 mr10">
            <button
              onClick={this.changepwd}
              type="button"
              className=" btn btn-primary mr10"
            >
              Change Password
            </button>

            <button
              onClick={this.cancel}
              type="button"
              className=" btn btn-primary mr10"
            >
              Cancel
            </button>

          </div>
        </div>
      </div>
    );
  }
}
