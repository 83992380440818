import React from "react";
import { Apiserver } from "../core/Apiserver";
import { CoreComponent } from "../core/CoreComponent";
import { UserSession } from "../core/UserSession";

export class Logout extends CoreComponent {

  constructor(props) {
    super();
    UserSession.deleteUserInfo();
    props.history.push("/login");
  }
  render() {
    return (<></>);
  }
}
