import React from "react";
import { CoreComponent } from "../core/CoreComponent";
import { log, addData, Validator, getValue } from "../core/Util";
import { DBConstants, ErrorMsg } from "../core/Constants";
import { Apiserver } from "../core/Apiserver";
import { BannerBar } from "../core/MenuBar";

export class Signup extends CoreComponent {

  constructor(props) {
    super(props);
    this.state.showlogin = false
    try {
      this.state.email = atob(props.match.params.enc_email)
    }
    catch (err) {
      this.state.email = ""
    }
  }

  backtologin = () => {
    this.redirect(this.props, '/login')
  }

  handleCancelButtonClick = () => {
    this.redirect(this.props, '/login')
  }

  handleButtonClick = () => {

    let error = {};
    Validator.checkValidString(error, "password1");
    Validator.checkValidString(error, "password2");

    if (Validator.dict_size(error) == 0) {
      let value1 = getValue("password1");
      let value2 = getValue("password2")
      if (value1 != value2)
        error["password1"] = ErrorMsg.PWD_MIS_MATCH;
    }

    if (Validator.dict_size(error) != 0) {
      this.setState({ error: error })
    } else {
      this.postdata()
    }
  }

  async postdata() {

    let datain = {}

    datain[DBConstants.Email_ID] = this.props.match.params.enc_email
    datain[DBConstants.INFO] = this.props.match.params.info
    datain[DBConstants.NEW_PWD] = getValue("password1")

    this.showAnimation();
    let response = await Apiserver.postcall("sign_up", datain);
    this.closeAnimation();

    let error = {};
    if (!Validator.checkResponseData(response, error)) {
      if (error.backenderror == DBConstants.ACCOUNT_ALREADY_SETUP)
        this.setState({ error: error, showlogin: true });
      else
        this.setState({ error: error });
      window.scrollTo({ top: 0, behavior: "smooth" });
    } else {
      if (response.data.MoreInfo)
        this.redirectWithMessage(this.props, "/login", "You cannot sign-up again, please login!")
      else {
        let data = { "emailid": this.state.email }
        this.redirectWithMessageAndData(this.props, "/login", "Account is setup, please login!", data)
      }
    }
  }

  render() {
    return (
      <div className="d-flex flex-row m0 p0 hfull backimg justify-content-center">
        <div className="col col-lg-5 col-md-6 col-s-12 fs15 m20 mt30 p0 formborder justify-content-center mh500">
          <BannerBar />
          <div>
          </div>

          <div className="mt20 ml10 mr10">
            {this.getMessagePanel()}

            <label htmlFor="username" className="row p0 m0 form-label">
              Please setup a new password to access your account &nbsp; <span className="fcmaroon" >{this.state.email && this.state.email}</span>
            </label>

            <label htmlFor="username" className="propertyname form-label mt10">
              Password:
            </label>
            {this.state.error.password1 && (
              <div className="alert alert-danger">{this.state.error.password1}</div>
            )}
            <input
              type="password"
              className="form-control"
              id="password1"
              size="5"
            />
            <label htmlFor="username" className="propertyname form-label mt20">
              Re-enter Password:
            </label>
            {this.state.error.password2 && (
              <div className="alert alert-danger">{this.state.error.password2}</div>
            )}
            <input
              type="password"
              className="form-control"
              id="password2"
              size="5"
            />
          </div>
          <div className="d-flex flex-row hc vc mt20 ml10 mr10">
            {!this.state.showlogin ? (<button onClick={this.handleButtonClick} type="button" className=" btn btn-primary" >
              Setup my account
            </button>) : (<button onClick={this.backtologin} type="button" className=" btn btn-primary" >
              Back To Login
            </button>)}
            <button onClick={this.handleCancelButtonClick} type="button" className=" btn btn-primary ml10" >
              Cancel
            </button>
          </div>
        </div>
      </div>
    );
  }
}
