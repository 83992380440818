import React from "react";
import { DBConstants } from "./Constants";
import { Apiserver } from "./Apiserver";


export class UserSession {

  static emailid = "";

  static account = {
    identifier: "",
    id: "",
    accounttype: 0,
    "token": null
  }

  static topBarRef
  static sideBarRef
  static mobileBarRef

  static isLoggedIn() {
    if (UserSession.account.token && UserSession.account.token != undefined && UserSession.account.token.trim().length > 10)
      return true;

    return false;
  }
  static fillAccountdetails(dict) {
    
    if (UserSession.isCorporate()){
      dict["corporateid"] = UserSession.account.id
      dict["accounttype"] = DBConstants.ACCOUNT_TYPE_CORPORATE
    }
    else if (UserSession.isUser()){
      dict["userid"] = UserSession.account.id
      dict["accounttype"] = DBConstants.ACCOUNT_TYPE_USER
    }
  }
  static setId(dict) {
    if (UserSession.isUser)
      dict[DBConstants.USER_ID] = UserSession.account.id
    else
      dict[DBConstants.Corporate_ID] = UserSession.account.id
  }

  static changeBars() {

    if (UserSession.topBarRef)
      UserSession.topBarRef.forceUpdate()

    if (UserSession.sideBarRef)
      UserSession.sideBarRef.forceUpdate()

    if (UserSession.mobileBarRef)
      UserSession.mobileBarRef.forceUpdate()
  }

  static setToCorporateId(corporateid) {
    UserSession.account.id = corporateid;
    UserSession.account.accounttype = DBConstants.ACCOUNT_TYPE_CORPORATE
    UserSession.account.identifier = "Admin(Corp:" + corporateid + ")"
    UserSession.changeBars()
  }

  static setToUserId(userId) {
    UserSession.account.id = userId;
    UserSession.account.accounttype = DBConstants.ACCOUNT_TYPE_USER
    UserSession.account.identifier = "Admin(Corp:" + userId + ")"
    UserSession.changeBars()
  }
  static setToAdmin() {

    if (UserSession.account.accounttype != DBConstants.ACCOUNT_TYPE_ADMIN &&
      UserSession.account.accounttype != DBConstants.ACCOUNT_TYPE_DEMO) {
      UserSession.loadUserInfo()
      UserSession.changeBars()
    }
  }
  static getIdentifier() {
    return UserSession.account.identifier
  }

  static isCorporate() {
    if (UserSession.account.accounttype == DBConstants.ACCOUNT_TYPE_CORPORATE)
      return true;
    return false;
  }

  static isUser() {
    if (UserSession.account.accounttype == DBConstants.ACCOUNT_TYPE_USER)
      return true;
    return false;
  }

  static isAdmin() {
    let role = UserSession.getCookie("at")
    if (role == DBConstants.ACCOUNT_TYPE_ADMIN || role == DBConstants.ACCOUNT_TYPE_DEMO)
      return true;

    return false;
  }

  static saveUserinfo() {
    UserSession.setCookie("tk", UserSession.account.token)
    UserSession.setCookie("id", UserSession.account.id)
    UserSession.setCookie("at", UserSession.account.accounttype)
    UserSession.setCookie("idf", UserSession.account.identifier)
    Apiserver.setToken(UserSession.account.token);
  }

  static loadUserInfo() {
    UserSession.account.token = UserSession.getCookie("tk")
    UserSession.account.id = UserSession.getCookie("id")
    UserSession.account.accounttype = UserSession.getCookie("at")
    UserSession.account.identifier = UserSession.getCookie("idf")
    Apiserver.setToken(UserSession.account.token);
  }
  static deleteUserInfo() {
    UserSession.account.token = ""
    UserSession.account.id = ""
    UserSession.account.accounttype = ""
    UserSession.account.identifier = ""
    UserSession.setCookie("tk", "")
    UserSession.setCookie("id", "")
    UserSession.setCookie("at", "")
    UserSession.setCookie("idf", "")
    Apiserver.setToken("");
  }

  static setCookie(cname, cvalue) {
    var d = new Date();
    d.setTime(d.getTime() + (1 * 24 * 60 * 60 * 1000));
    var expires = "expires=" + d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
  }

  static getCookie(cname) {
    var name = cname + "=";
    var decodedCookie = decodeURIComponent(document.cookie);
    var ca = decodedCookie.split(';');
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) == ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }
}
